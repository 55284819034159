import React from 'react'
import tech from '../images/technology.png';
import code from "../images/personal.png";
import interview from "../images/interview.png"
import skill from "../images/customer-service.png"
import ebook from "../images/ebook.png"

const WhyChooseUs = () => {
  return (
    <div className="bg-gray-100 py-12">
       <section>
            <div className='flex justify-center mb-4'>
                            <div className='w-24 border-b-4 border-[#E9242D]'></div>
                        </div>
                <div className=" m-auto max-w-6xl px-2 md:px-12 h-5/6">
                <h1 className='  mx-12 text-center text-xl lg:text-2xl font-semibold text-[#E9242D]'>
                        Why Choose Luminaakar EdTech?
                        </h1>
                    <div className=" sm:mx-4 mx-8 flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            <img src={tech} alt="" className=' sm:w-20 w-10  ' /> 
                            </div>
                            <h3 className="text-3xl  text-[#E9242D] 
                            font-bold"><span className='font-black'>Industry-Relevant Curriculum</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Our courses are designed by industry experts to equip you with practical skills and knowledge.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-[#E9242D]  mb-4'>
                            <img src={code} alt="" className='sm:w-20 w-10 ' />
                            </div>
                            <h3 className="text-3xl  text-[#E9242D] font-bold"><span className='font-black'> Personalized Learning</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Tailored guidance and mentorship to help you excel in your chosen field.</p>
                            </div>
                        </div>
                       
                       
                    </div>
                    <div className=" sm:mx-4 mx-8  flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            <img src={interview} alt="" className=' sm:w-20 w-10  ' /> 
                            </div>
                            <h3 className="text-3xl  text-[#E9242D] 
                            font-bold"><span className='font-black'>Hands-On Experience</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Real-world projects and internship opportunities to apply your learning.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-[#E9242D]  mb-4'>
                            <img src={skill} alt="" className='sm:w-20 w-10 ' />
                            </div>
                            <h3 className="text-3xl  text-[#E9242D]  font-bold"><span className='font-black'>Placement Support</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'> Collaborations with top companies for internships and job placements.</p>
                            </div>
                        </div>
                       </div>
                       <div className=" sm:mx-4 mx-8  flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                            <img src={ebook} alt="" className=' sm:w-20 w-10  ' /> 
                            </div>
                            <h3 className="text-3xl  text-[#E9242D] 
                            font-bold"><span className='font-black'>Flexible Learning</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                Choose any domain of interest and upskill at your own pace.
                                </p>
                            </div>
                        </div>
                       </div>

                       
                </div>
            </section>
    </div>
  )
}

export default WhyChooseUs

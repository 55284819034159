import React from 'react';
import img from '../images/about.svg';
import { Link } from 'react-router-dom';



const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center items-center">
                            <img alt="card img" className="rounded-t lg:w-[70%] w-full " src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl text-[#E9242D] ">About <span className=' font-bold'>Luminaakar EdTech</span></h3>
                            <div>
                                <p className='my-3 text-justify text-xl text-gray-600 font-semibold'>Welcome to Luminaakar EdTech, where innovation meets education. At Luminaakar, we are committed to shaping the future of learning by providing high-quality, industry-relevant training and skill development programs.</p>
                            </div>
            
                            <Link to="/contact" className="text-white bg-[#E9242D] hover:bg-[#D12028] inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>

                        </div>
                    </div>
                    <section data-aos="zoom-in-down">
                    <div className=' flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left'>
                    
                        <div className=' lg:w-3/4 flex flex-col lg:mx-4 mx-7 justify-center'>
                            <p className=' my-3 text-justify text-xl text-gray-600 font-semibold'> 
                            Our mission at Luminaakar EdTech is to empower individuals with the knowledge and skills needed to succeed in today's rapidly evolving technological landscape. We believe in fostering a culture of continuous learning and innovation, enabling our students to stay ahead in their careers and achieve their professional goals.
                            </p>
                        </div>
                        <div className=' pt-4 lg:mx-4 mx-7 '>
                            <h3 className=' text-2xl text-gray-600 font-semibold max-lg:text-center'>
                            Our<br/><span className=' font-bold text-5xl text-[#E9242D]'>Mission</span> 
                            </h3>
                        </div>
                        
                    </div></section>
                </div>
        </>
    )
}

export default Intro;
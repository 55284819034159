import React from 'react';
import img from '../images/expert.svg'; 
import img2 from '../images/Learning.svg';
import img3 from '../images/Support.svg';
import img4 from '../images/interview.svg';



const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4 ">
                        <h2 className="my-2 text-center text-3xl text-[#E9242D]  uppercase font-bold">services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-[#E9242D]'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#E9242D]">Here's Why you should join us ?</h2>
                    </div>

                    <div className="px-12 mb-36" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Expertise</h2>
                                    <p className="text-md font-medium">
                                    Our courses are designed and taught by industry experts with extensive experience in their respective fields. We ensure that our students receive the most up-to-date and relevant knowledge.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Practical Learning</h2>
                                    <p className="text-md font-medium">
                                    We emphasize hands-on, practical learning experiences to ensure our students are well-prepared for the challenges of the real world. From industry projects to internships, we provide opportunities for students to apply their skills in real-world scenarios.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Personalized Support</h2>
                                    <p className="text-md font-medium">
                                    We understand that every student is unique, which is why we offer personalized support and mentorship to help our students succeed. Whether it's academic guidance or career advice, our team is here to support you every step of the way.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Industry Connections</h2>
                                    <p className="text-md font-medium">
                                    Through our partnerships with leading companies and organizations, we provide our students with access to internships, job placements, and networking opportunities. We believe in building strong connections within the industry to help our students launch successful careers.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;
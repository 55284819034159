import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import { Toaster } from "react-hot-toast";
import Error from './components/Error';

// import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Form from './pages/Form';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Luminaakar EdTeach");

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Router>
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/404error' element={<Error />} />
          <Route path="*" element={<Navigate to="/404error" replace={true} />} />
          <Route path="/form" element={<Form />} />
        </Routes> 
        <ScrollToTop />
      </Router>
      <Toaster position="top-center" />
    </>
  );
}

export default App;

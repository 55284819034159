import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import {toast} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';





const Form = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const { firstName, lastName, email, phone} = formData;
        
        // if(!firstName) {
        //     return toast.error("Please enter first name");
        // }
        // if(!lastName) {
        //     return toast.error("Please enter last name");
        // }
        // if(!email) {
        //     return toast.error("Please enter email");
        // }
        // if(!EMAIL_REGEX.test(email)) {
        //     console.log("dwdwd", firstName, lastName, email, phone, message);
        //     return toast.error("Please enter a valid email address");
        // }
        // if(!phone) {
        //     return toast.error("Please enter phone number");
        // }
        // if(!PHONE_REGEX.test(phone)) {
        //     return toast.error("Please enter a valid phont number");
        // }

        const { firstName, lastName, email, phone} = formData;

        // Ensure all required fields are filled
        if (!firstName || !lastName || !email || !phone) {
            alert('Please fill out all required fields.');
            return;
        }

        const templateParams = {
            from_firstName: firstName,
            from_lastName: lastName,
            from_email: email,
            from_phone: phone
        };

   
    try {
        // Send email using EmailJS
        const serviceId = 'service_8chd50d';
        const templateId = 'template_qpa2mpg';
        const userId = '0p9o7yJQpwJS2LbjU';
        await emailjs.send(serviceId, templateId, templateParams, userId);
        console.log('Email sent successfully');
  
        // pre-existing pdf files
        const pdfUrls = [
          'https://www.luminaakar.in/Data-Analytics-Brochure.pdf',
          'https://www.luminaakar.in/UIUX-Designing-Brochure.pdf',
          'https://www.luminaakar.in/Mern-Stack-Development.pdf'
        ];
  
        pdfUrls.forEach((pdfUrl, index) => {
            // Extract filename from URL
            const filename = pdfUrl.substring(pdfUrl.lastIndexOf('/') + 1);
          
            // Create a link element
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = filename; // Set the download filename
            link.target = '_blank'; // Open in a new tab
            link.textContent = `Open ${filename} in new tab`;
            link.click();
          });
  
        // Clear form data after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        });
  
        toast.success('Message sent successfully!');
        navigate('/#hero');
      } catch (error) {
        console.error('Email sending failed:', error);
        toast.error('Failed to send message. Please try again later.');
      }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

  return (

    <>
    <div>
        <NavBar />
    </div>
    <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

        <form onSubmit={handleSubmit}>
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div className="flex">
                    <h1 className="font-bold text-center lg:text-left text-[#E9242D]  text-3xl">Please fill out the form below to Download our Brochure</h1>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                <input 
                                        name="firstName" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="First Name*" 
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                    
                                </div>
                                
                                <div>
                                <input 
                                        name="lastName" 
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text" 
                                        placeholder="Last Name*"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                </div>
                                <div className=' grid grid-cols-1 gap-5 md:grid-cols-1 mt-5'>

                                <div>
                                <input 
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email" 
                                        placeholder="Email*"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>

                                <div>
                                <input
                                 name="phone" 
                                 className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                 type="tel" 
                                 placeholder="Phone*"
                                 maxLength={10} // Limit the maximum length to 10 characters
                                 pattern="[0-9]{10}" // Only allow exactly 10 numeric characters
                                 value={formData.phone}
                                 onChange={handleChange}
                                 required
                                 />
                                   
                                </div>
                        </div>
                <div className="my-2 w-1/2 lg:w-2/4">
                    <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-[#E9242D] text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                        Submit
                    </button>
                </div>
            </div>
        </form>
                <div
                    className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-[#d12028] rounded-2xl">
                    <div className="flex flex-col text-white">
                        
                        <div className="flex my-4 w-2/3 lg:w-3/4">
                            <div className="flex flex-col">
                                <i className="fas fa-map-marker-alt pt-2 pr-2" />
                            </div>
                            <div className="flex flex-col">
                                <h2 className="text-2xl">Address</h2>
                                <p className="text-gray-400">401, Sai Ram Plaza, Mangal Nagar, Indore</p>
                            </div>
                        </div>
            
            <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                <div className="flex flex-col">
                <h2 className="text-2xl">Contact Us</h2>
                <p className="text-gray-400">9174560271, <br/> 9620864378</p>
                
                    <div className='mt-5'>
                        <h2 className="text-2xl">Send an E-mail</h2>
                        <p className="text-gray-400">luminaakar15@gmail.com</p>
                    </div>
               
                </div>
            </div>
            
            <div className="flex my-4 w-2/3 lg:w-1/2">
                <a href="/#about" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-[#E9242D]  w-8  mx-1 text-center pt-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                </a>
                <a href="/#about" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-[#E9242D]  w-8  mx-1 text-center pt-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                </a>
            </div>
            </div>
        </div>
        </div>
    </div>
    <Footer />
</>
  )
}

export default Form

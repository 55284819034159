import React from 'react';
import computer from "../images/computer-engineering.png"
import engine from "../images/engine.png"
import manage from "../images/management.png"



const Clients = () => {
    return (
        <div className="mt-8 mb-8   bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-[#E9242D] uppercase font-bold">Our Programs</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-[#E9242D]'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-500">At Luminaakar EdTech, we offer a wide range of programs spanning various domains, including</h2>
                    </div>
                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
        <div className="p-3 flex flex-col items-center justify-center transition-all ease-in-out opacity-50 hover:opacity-100 mb-5 sm:mb-0" >
            <div className='mb-4'>
                <img src={computer} className='w-40' alt=''/>
            </div>
            <div>
                <p className='text-lg lg:text-xl font-semibold text-gray-500'>
                    Computer Science and Engineering
                </p>
            </div>
        </div>
        <div  className="p-3 flex flex-col items-center justify-center transition-all ease-in-out opacity-50 hover:opacity-100 mb-5 sm:mb-0">
            <div className='mb-4'>
                <img src={engine} className='w-40' alt=''/>
            </div>
            <div>
                <p className='text-lg lg:text-xl font-semibold text-gray-500'>
                    Electronics and Electrical Engineering
                </p>
            </div>
        </div>
        <div className="p-3 flex flex-col items-center justify-center transition-all ease-in-out opacity-50 hover:opacity-100 mb-5 sm:mb-0" >
            <div className='mb-4'>
                <img src={manage} className='w-40' alt=''/>
            </div>
            <div>
                <p className='text-lg lg:text-xl font-semibold text-gray-500'>
                    Management
                </p>
            </div>
        </div>
    </div>
</div>

            </section>
        </div>
    )
}

export default Clients;
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import {toast} from 'react-hot-toast';

const Services = () => {
    const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const PHONE_REGEX = /^[0-9]{10}$/;
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNewDropdownOpen, setIsNewDropdownOpen] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const { firstName, lastName, email, phone } = formData;
  
      if (!firstName || !lastName || !email || !phone) {
        return toast.error('Please fill in all fields');
      }
      if (!EMAIL_REGEX.test(email)) {
        return toast.error('Please enter a valid email address');
      }
      if (!PHONE_REGEX.test(phone)) {
        return toast.error('Please enter a valid phone number');
      }
  
      const templateParams = {
        from_firstName: firstName,
        from_lastName: lastName,
        from_email: email,
        from_phone: phone,
      };
  
      try {
        const serviceId = 'service_8chd50d';
        const templateId = 'template_qpa2mpg';
        const userId = '0p9o7yJQpwJS2LbjU';
        await emailjs.send(serviceId, templateId, templateParams, userId);
  
        toast.success('Email sent successfully');
        setIsDropdownOpen(false); // Close form dropdown
        setIsNewDropdownOpen(true); // Open new dropdown
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        });
      } catch (error) {
        console.error('Email sending failed:', error);
        toast.error('Failed to send message. Please try again later.');
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    // download pdf section 
    const pdf_URL_1 = 'https://www.luminaakar.in/Data-Analytics-Brochure.pdf';
    const pdf_URL_2 = 'https://www.luminaakar.in/Mern-Stack-Development.pdf';
    const pdf_URL_3 = 'https://www.luminaakar.in/UIUX-Designing-Brochure.pdf';

    function downloadPdfUrl(Url) {
        const fileName = Url.split('/').pop();
        const a = document.createElement('a');
        a.href = Url;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

  return (
    <div className='bg-gray-100 px-12 py-12'>
      <section data-aos="zoom-in-down">
      <div>
        <div className=' flex flex-col lg:flex-row justify-center gap-5 lg:text-left'>
        <div className='  lg:mx-4 mx-7'>
        <h3 className=' text-2xl text-gray-600 font-semibold max-lg:text-center'>
        Our<br/><span className=' font-bold text-5xl text-[#E9242D]'>Course</span> 
        </h3>
        </div>
        <div>
        
        <div className=' flex flex-col lg:mx-4 mx-7 justify-center '>
        <p className=' my-1 text-justify text-xl text-gray-600 font-semibold'> 
        Explore our range of specialized courses designed to enhance your skills and expertise.
        </p>
        </div>
        <div className='lg:mx-4 mx-7 mb-4 justify-center'>
        <button
        className='text-white bg-[#E9242D] hover:bg-[#D12028] inline-flex items-center justify-center w-full px-4 py-2 my-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0'
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>know more
        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
       </button>
        </div>
        </div>
        </div>
        {isDropdownOpen && (
          <div className=' flex justify-center items-center h-full mb-4'>
               <form onSubmit={handleSubmit}>

   <div className="w-full bg-white p-8  md:px-12  rounded-2xl shadow-2xl">
    <div>
        <h1 className="font-bold text-center lg:text-left text-[#E9242D]  text-3xl">Fill the below giving form to know more about our Course</h1>
    </div>
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
            <div>
            <input 
                    name="firstName" 
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text" 
                    placeholder="First Name*" 
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
                
            </div>
            
            <div>
            <input 
                    name="lastName" 
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text" 
                    placeholder="Last Name*"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
            </div>

            <div>
            <input 
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email" 
                    placeholder="Email*"
                    value={formData.email}
                    onChange={handleChange}
                    required 
                />
            </div>

            <div>
          <input
             name="phone" 
             className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
             type="tel" 
             placeholder="Phone*"
             maxLength={10} // Limit the maximum length to 10 characters
             pattern="[0-9]{10}" // Only allow exactly 10 numeric characters
             value={formData.phone}
             onChange={handleChange}
             required
             />


               
            </div>
    </div>
    <div className="my-4">
   
    </div>
    <div className="my-2 w-1/2 lg:w-2/4">
    <button
     type='submit'
     className='uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-[#E9242D] text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline'>
     Submit</button>
    </div>
</div>
</form>
  </div>
        )}
      </div>
      {isNewDropdownOpen && (
       <div className='py-12 px-12 bg-white rounded-2xl shadow-2xl flex justify-center items-center h-screen'>
      <div className='flex flex-col gap-5'>
    {[
        {url: pdf_URL_1, label: 'Data Analytics'},
        {url: pdf_URL_2, label: 'Mern Stack Development'},
        {url: pdf_URL_3, label: 'UIUX Designing'}
    ].map(({url, label}, index) => (
        <div key={index} className='flex flex-row items-center gap-5'>
            <button
                className='text-white bg-[#E9242D] hover:bg-[#D12028] inline-flex items-center justify-center px-4 py-2 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0'
                onClick={() => downloadPdfUrl(url)}>
                <svg className='w-5 h-5 mr-1' fill="currentColor" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m6.864 3.424c.502-.301 1.136.063 1.136.642 0 .264-.138.509-.365.644-2.476 1.486-4.135 4.197-4.135 7.292 0 4.691 3.808 8.498 8.498 8.498s8.497-3.807 8.497-8.498c0-3.093-1.656-5.803-4.131-7.289-.225-.136-.364-.38-.364-.644 0-.582.635-.943 1.137-.642 2.91 1.748 4.858 4.936 4.858 8.575 0 5.519-4.479 9.998-9.997 9.998s-9.998-4.479-9.998-9.998c0-3.641 1.951-6.83 4.864-8.578zm.831 8.582s2.025 2.021 3.779 3.774c.147.147.339.22.53.22.192 0 .384-.073.531-.22 1.753-1.752 3.779-3.775 3.779-3.775.145-.145.217-.336.217-.526 0-.192-.074-.384-.221-.531-.292-.293-.766-.294-1.056-.004l-2.5 2.499v-10.693c0-.414-.336-.75-.75-.75s-.75.336-.75.75v10.693l-2.498-2.498c-.289-.289-.762-.286-1.054.006-.147.147-.221.339-.222.531 0 .19.071.38.215.524z"/>
                </svg>
                Download
            </button>
            <p className='text-center text-xl text-gray-600 font-semibold'>{label}</p>
        </div>
    ))}
</div>

   </div>
   
    
      )}
      </section>
    </div>
  )
}

export default Services
